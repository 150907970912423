/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* =============================================================================
           primary navigation
           ========================================================================== */

        // If a link has a dropdown, add sub menu toggle.
        $('nav.nav-primary ul li a:not(:only-child)').click(function(e) {
          $(this).siblings('.sub-menu').slideToggle(900, "swing", function() {});
          // Close one dropdown when selecting another
          $('.sub-menu').not($(this).siblings()).hide();
          e.stopPropagation();
        });
        // If a dropdown link is clicked, toggle active class.
        $('nav.nav-primary ul li a:not(:only-child)').click(function(e) {
          $(this).toggleClass('active');
        });
        // Clicking away from dropdown will remove the active class and hide the .sub-menu
        $('html').click(function() {
          $('.sub-menu').hide();
          $('nav.nav-primary ul li a:not(:only-child)').removeClass('active');
        });
        // Toggle open and close nav styles on click
        $('.nav-toggle').click(function() {
          $('nav.nav-primary ul').slideToggle(900, "swing", function() {});
        });
        // Hamburger to X toggle
        $('.nav-toggle').on('click', function() {
          this.classList.toggle('active');
        });

        /* =============================================================================
           modaal
           http://humaan.com/modaal/
           ========================================================================== */

        $('.inline').modaal({
          type: 'inline'
        });

        // $('.close').modaal('close');

        $( ".close-modaal" ).click(function() {
          $( "#modaal-close" ).click();
        });

        // $(".info--1").modaal({
        //     content_source: '#info--1'
        // }); regex [a-zA-Z0-9-_\\s]

        $('.video').modaal({
          type: 'video'
        });

        /* =============================================================================
           testimonials
           ========================================================================== */

        $('.front-page-testimonials .slide');
          setInterval(function(){
          $('.front-page-testimonials .slide').filter(':visible').fadeOut(1000,function(){
            if($(this).next('.slide').size()){
              $(this).next().fadeIn(1000);
            }
            else{
              $('.blockquote .slide').eq(0).fadeIn(1000);
            }
          });
        },8000);

        /* =============================================================================
         smooth-scroll
         https://github.com/cferdinandi/smooth-scroll

         note: installed via bower (should be npm install)

         bower install https://github.com/cferdinandi/smooth-scroll.git
         then
         manually add to bower.json and add overrides

         "dependencies": {
            "smooth-scroll": "^12.1.5"
          },
          "overrides": {
            "smooth-scroll": {
              "main": [
                "./dist/js/smooth-scroll.js"
              ]
            }
          }
         ========================================================================== */

        var scroll = new SmoothScroll('a[href*="#"]', {
          speed: 1600, // Integer. How fast to complete the scroll in milliseconds
          offset: 40, // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
          easing: 'easeInOutCubic', // Easing pattern to use
          customEasing: function (time) {}, // Function. Custom easing pattern
        });

        /* =============================================================================
           matchHeight
           ========================================================================== */

        $('.eqheight').matchHeight({
          property: 'height',
          byRow: false
        });

        $('.eqheight-row').matchHeight({
          property: 'height'
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'blog': {
      init: function() {
        // JavaScript to be fired on the about us page

        /* =============================================================================
           isotope
           requires imagesLoaded - bower install imagesloaded --save
           ========================================================================== */
        // var $container = $('.isotope-list').imagesLoaded( function() {
        var $container = $('.isotope-list'); //The ID for the list with all the blog posts
        $container.imagesLoaded(function(){
          $container.isotope({
            // options
            itemSelector : '.item',
            layoutMode : 'masonry'
          });
        });
        // });

        // Add the class selected to the item that is clicked, and remove from the others
        var $optionSets = $('#filters'),
        $optionLinks = $optionSets.find('a');

        $optionLinks.click(function(){
        var $this = $(this);
        // don't proceed if already selected
        if ( $this.hasClass('selected') ) {
          return false;
        }
        var $optionSet = $this.parents('#filters');
        $optionSets.find('.selected').removeClass('selected');
        $this.addClass('selected');

        // When an item is clicked, sort the items.
        var selector = $(this).attr('data-filter');
        $container.isotope({ filter: selector });

        return false;
        });

      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
